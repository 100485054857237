import { useState, useEffect } from 'react';
import queryString from 'query-string';

import { useApi } from './useApi';
import { generalConfig } from 'src/config';
import { Category } from 'src/utils/types';

export function useCategories() {
  const { get } = useApi();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function fetchCategories() {
    setLoading(true);
    try {
      const params = {
        tenantId: generalConfig.tenant.id,
        ignorePagination: true,
      };
      const query = queryString.stringify(params);
      const response = await get<Category[]>('/categories?' + query);
      setCategories(response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  const fetchCategoriesBasedOnGroup = async (groupId: number) => {
    setLoading(true);
    try {
      const response = await get<Category[]>('/categories/by-group/' + groupId);
      setCategories(response);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return { categories, loading, error, fetchCategoriesBasedOnGroup };
}
