import { useState } from 'react';
import { useApiAuth } from './useApiAuth';

export const useAttachments = () => {
  const { post } = useApiAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadAttachments = async (files: File[], cb?: Function) => {
    try {
      setLoading(true);
      const responses: string[] = await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const response = await post<string>('/uploads/attachments', formData);
          return response;
        })
      );
      setLoading(false);
      if (cb) cb();
      return responses;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return {
    uploadAttachments,
    loading,
  };
};
