import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled,
  Button,
  FormHelperText,
  TextField,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { useForm } from 'src/hooks';
import { UserLoginData } from 'src/utils/types';
import useAuth from 'src/hooks/useAuth';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg',
};

const CardImg = styled(Card)(
  ({ theme }) => `
    width: 90px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${theme.colors.alpha.white[100]};
    margin: 0 ${theme.spacing(1)};
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['all'])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const BottomWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
    justify-content: center;
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

const SearchTicket: FC = () => {
  const { t }: { t: any } = useTranslation();
  const { login } = useAuth();
  const { values, onChange } = useForm<UserLoginData>({
    email: null,
    code: null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      event.target.name as keyof UserLoginData,
      event.target.value.length ? event.target.value : null
    );
  };

  const handleLogin = async () => {
    await login(values.email, values.code);
  };

  return (
    <>
      <Helmet>
        <title>Busca tu ticket</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">
            {/* <Logo /> */}
            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3,
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                  }}
                >
                  Tickets
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3,
                  }}
                >
                  Llena los campos para buscar tu ticket y conocer su estado
                </Typography>
              </Box>

              <Formik
                enableReinitialize
                initialValues={{
                  email: values.email || '',
                  code: values.code || '',
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Debes ingresar un correo válido')
                    .max(255)
                    .required('El correo es requerido'),
                  code: Yup.string()
                    .max(255)
                    .required('El código es requerido'),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setSubmitting(true);
                    handleLogin();
                    // await login(values.email, values.password);
                    // if (isMountedRef.current) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    // }
                  } catch (err) {
                    console.error(err);
                    // if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                    // }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  touched,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      margin="normal"
                      helperText={touched.email && errors.email}
                      label="Correo"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email || ''}
                      placeholder="Ej: jhon.doe@email.com"
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.code && errors.code)}
                      fullWidth
                      margin="normal"
                      helperText={touched.code && errors.code}
                      label="Código"
                      placeholder="Ej: AAA-1234"
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.code || ''}
                      variant="outlined"
                    />

                    <Button
                      sx={{ mt: 3 }}
                      color="primary"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={isSubmitting}
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                    >
                      Buscar
                    </Button>
                  </form>
                )}
              </Formik>

              <Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  ¿Deseas crear un ticket?
                </Typography>{' '}
                <Link component={RouterLink} to="/create-ticket">
                  <b>Ingresa aquí</b>
                </Link>
              </Box>
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
};

export default SearchTicket;
