import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Container,
  Button,
  Card,
  CircularProgress,
  Grid,
  Box,
  Link,
  Collapse,
  Alert,
  Avatar,
  IconButton,
  styled,
  useTheme,
  CardActionArea,
  CardMedia,
  TextField,
  Autocomplete,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import ReactQuill from 'react-quill';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import 'react-quill/dist/quill.snow.css';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  Divider,
  Chip,
  DialogContent,
  Zoom,
  ListItem,
  List,
  ListItemText,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import Text from 'src/components/Text';
import Logo from 'src/components/LogoSign';

import {
  useAttachments,
  useCategories,
  useForm,
  useGroups,
  useTickets,
} from 'src/hooks';
import { CreateTicket, OptionLabel } from 'src/utils/types';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      margin-left: auto;
      margin-right: auto;

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.primary.lighter};
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

function CreateTicketComponent() {
  const theme = useTheme();
  const [openAlert, setOpenAlert] = useState(true);
  const { categories, fetchCategoriesBasedOnGroup } = useCategories();
  const { groups, levelNames } = useGroups({});
  const { values, onChange, reset, onMultiChange } = useForm<CreateTicket>({
    subject: null,
    body: null,
    categoryId: null,
    groupId: null,
    firstName: null,
    lastName: null,
    email: null,
  });
  const { createTicket } = useTickets({}, false);
  const [selectedLevels, setSelectedLevels] = useState<
    {
      level: number;
      id: number;
    }[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { uploadAttachments, loading: uploadingAttachment } = useAttachments();
  const [attachmentsUrls, setAttachmentsUrls] = useState<string[]>([]);
  const code = searchParams.get('code');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      event.target.name as keyof CreateTicket,
      event.target.value.length ? event.target.value : null
    );
  };

  const handleReset = (fn?: Function) => {
    reset();
    if (fn) fn();
  };

  const initializeSelectedLevels = () => {
    const selectedLevels: {
      level: number;
      id: number;
    }[] = [];
    levelNames.forEach((levelName) => {
      selectedLevels.push({
        level: levelName.level,
        id: null,
      });
    });
    setSelectedLevels(selectedLevels);
  };

  const handleSelectLevel = (level: number, id: number | null) => {
    const newSelectedLevels = [...selectedLevels].sort(
      (a, b) => a.level - b.level
    );
    newSelectedLevels[level - 1].id = id;

    // Now we need to reset the next levels
    for (let i = level; i < newSelectedLevels.length; i++) {
      newSelectedLevels[i].id = null;
    }

    // Check if we need to reset the state
    const selectedLevelOnState = values.groupId
      ? groups.filter((group) => `${group.id}` === `${values.groupId}`)[0].level
      : null;
    if (selectedLevelOnState && selectedLevelOnState > level) {
      onMultiChange({
        categoryId: null,
        groupId: null,
      });
    }

    setSelectedLevels(newSelectedLevels);
  };

  const isGroupLocked = (level: number) => {
    if (level === 1) return false;

    const previousLevel = selectedLevels[level - 2];
    if (!previousLevel.id) return true;
  };

  const groupsToRender = (level: number): OptionLabel[] => {
    if (level === 1) {
      return groups
        .filter((group) => group.level === 1)
        .map((group) => ({
          label: group.name,
          value: group.id,
        }));
    }

    const previousLevel = selectedLevels.filter(
      (selectedLevel) => selectedLevel.level === level - 1
    )[0];
    if (!previousLevel?.id) return [];

    return groups
      .filter(
        (group) =>
          group.level === level && group.parent?.id === previousLevel.id
      )
      .map((group) => ({
        label: group.name,
        value: group.id,
      }));
  };

  const handleSubmit = async (cb?: Function) => {
    const ticket = await createTicket(
      {
        ...values,
        attachmentsUrls,
      },
      cb,
      false
    );
    setSearchParams({ code: ticket.code });
  };

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg'],
      // PDF
      'application/pdf': ['.pdf'],
      // Excel
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    multiple: true,
    useFsAccessApi: false,
  });

  const handleFileUpload = async () => {
    const files = acceptedFiles.map((file) => file as File);
    const response = await uploadAttachments(files);
    setAttachmentsUrls((prev) => [...prev, ...response]);
  };

  useEffect(() => {
    if (!acceptedFiles.length) return;
    handleFileUpload();
  }, [acceptedFiles]);

  useEffect(() => {
    if (!values.groupId) return;

    fetchCategoriesBasedOnGroup(values.groupId);
  }, [values.groupId]);

  useEffect(() => {
    initializeSelectedLevels();
  }, [levelNames]);

  const files = attachmentsUrls.map((url, index) => (
    <ListItem disableGutters component="div" key={index}>
      <ListItemText primary={url.split('/').pop()} />
      <IconButton
        onClick={() => {
          setAttachmentsUrls((prev) => {
            const newAttachmentsUrls = [...prev];
            newAttachmentsUrls.splice(index, 1);
            return newAttachmentsUrls;
          });
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
    </ListItem>
  ));

  return (
    <>
      <Helmet>
        <title>Crea tu ticket</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4,
          }}
          maxWidth="md"
        >
          {/* <Logo /> */}
          <Card
            sx={{
              mt: 3,
              pt: 4,
            }}
          >
            <Box px={4}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                }}
              >
                Crear ticket
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3,
                }}
              >
                Llena el formulario a continuación para crear un ticket con tu
                solicitud
              </Typography>
            </Box>

            <Box>
              {!code ? (
                <Formik
                  initialValues={{
                    categoryId: values.categoryId || '',
                    groupId: values.groupId || '',
                    firstName: values.firstName || '',
                    lastName: values.lastName || '',
                    email: values.email || '',
                    subject: values.subject || '',
                    body: values.body || '',
                    submit: null,
                  }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    categoryId: Yup.string().required(
                      'La categoría es requerida'
                    ),
                    groupId: Yup.string().when([], {
                      is: () => levelNames.length > 0,
                      then: Yup.string().required(
                        'Debe seleccionar todas las clasificaciones'
                      ),
                      otherwise: Yup.string(),
                    }),
                    firstName: Yup.string()
                      .max(50, 'El nombre debe tener menos de 50 caracteres')
                      .required('El nombre es requerido'),
                    lastName: Yup.string()
                      .max(50, 'El apellido debe tener menos de 50 caracteres')
                      .required('El apellido es requerido'),
                    email: Yup.string()
                      .email(
                        'Debe ser una dirección de correo electrónico válida'
                      )
                      .required('El correo es requerido'),
                    subject: Yup.string()
                      .max(50, 'El asunto debe tener menos de 50 caracteres')
                      .required('El asunto es requerido'),
                    body: Yup.string()
                      .max(
                        1024,
                        'El mensaje debe tener menos de 1024 caracteres'
                      )
                      .required('El mensaje es requerido'),
                  })}
                  onSubmit={(
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setSubmitting(true);
                      handleSubmit(() => {
                        resetForm();
                        setStatus({ success: true });
                        setSubmitting(false);
                        reset();
                      });
                    } catch (err) {
                      console.error(err);
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleSubmit,
                    touched,
                    isSubmitting,
                    resetForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Timeline>
                        {/* Grupos */}
                        {levelNames.length > 0 && (
                          <TimelineItem
                            sx={{
                              p: 0,
                            }}
                          >
                            <TimelineOppositeContent
                              sx={{
                                width: '100px',
                                flex: 'none',
                              }}
                              color="text.secondary"
                            >
                              Grupos
                            </TimelineOppositeContent>
                            <TimelineSeparator
                              sx={{
                                position: 'relative',
                              }}
                            >
                              <TimelineDot
                                sx={{
                                  marginTop: 0,
                                  top: theme.spacing(1.2),
                                }}
                                variant="outlined"
                                color="primary"
                              />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent
                              sx={{
                                pb: 0,
                              }}
                            >
                              <Box>
                                <Typography variant="h5" gutterBottom>
                                  Cuéntanos de dónde viene tu solicitud
                                </Typography>
                                <Grid container spacing={4}>
                                  {groups.length > 0 &&
                                    levelNames.length > 0 &&
                                    selectedLevels.length > 0 &&
                                    levelNames.map((levelName) => (
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        key={levelName.id}
                                      >
                                        <Autocomplete
                                          disabled={isGroupLocked(
                                            levelName.level
                                          )}
                                          value={
                                            selectedLevels.filter(
                                              (selectedLevel) =>
                                                selectedLevel.level ===
                                                levelName.level
                                            )[0]?.id
                                              ? groups
                                                  .filter(
                                                    (group) =>
                                                      `${group.id}` ===
                                                      `${
                                                        selectedLevels.filter(
                                                          (selectedLevel) =>
                                                            selectedLevel.level ===
                                                            levelName.level
                                                        )[0]?.id
                                                      }`
                                                  )
                                                  .map((selectedGroup) => ({
                                                    label: selectedGroup.name,
                                                    value: selectedGroup.id,
                                                  }))[0]
                                              : null
                                          }
                                          options={groupsToRender(
                                            levelName.level
                                          )}
                                          getOptionLabel={(
                                            option: OptionLabel
                                          ) => option.label}
                                          onChange={(_, value: OptionLabel) => {
                                            if (!value) {
                                              handleChange({
                                                target: {
                                                  name: 'groupId',
                                                  value: '',
                                                },
                                              } as any);
                                              handleSelectLevel(
                                                levelName.level,
                                                null
                                              );
                                              return;
                                            }
                                            handleSelectLevel(
                                              levelName.level,
                                              value.value
                                            );
                                            if (
                                              levelName.level <
                                              levelNames.length
                                            )
                                              return;
                                            handleChange({
                                              target: {
                                                name: 'groupId',
                                                value: `${value.value}`,
                                              },
                                            } as any);
                                          }}
                                          noOptionsText="No hay opciones. Intenta con otro grupo o contacta a soporte."
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="outlined"
                                              label={levelName.name}
                                              placeholder={levelName.name}
                                              error={Boolean(
                                                touched.groupId &&
                                                  errors.groupId &&
                                                  levelName.level ===
                                                    levelNames.length
                                              )}
                                              helperText={
                                                touched.groupId &&
                                                levelName.level ===
                                                  levelNames.length &&
                                                errors.groupId
                                              }
                                              disabled={isGroupLocked(
                                                levelName.level
                                              )}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    ))}
                                  <Grid item xs={12} md={6} />
                                </Grid>
                              </Box>
                            </TimelineContent>
                          </TimelineItem>
                        )}

                        {/* Categorías */}
                        <TimelineItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <TimelineOppositeContent
                            sx={{
                              width: '100px',
                              flex: 'none',
                            }}
                            color="text.secondary"
                          >
                            Categoría
                          </TimelineOppositeContent>
                          <TimelineSeparator
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TimelineDot
                              sx={{
                                marginTop: 0,
                                top: theme.spacing(1.2),
                              }}
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            sx={{
                              pb: 0,
                            }}
                          >
                            <Box>
                              <Typography variant="h5" gutterBottom>
                                ¿De qué trata tu solicitud?
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                  <Autocomplete
                                    disabled={
                                      levelNames.length > 0 && !values.groupId
                                    }
                                    value={
                                      values.categoryId
                                        ? categories
                                            .filter(
                                              (category) =>
                                                `${category.id}` ===
                                                `${values.categoryId}`
                                            )
                                            .map((selectedCategory) => ({
                                              label: selectedCategory.name,
                                              value: selectedCategory.id,
                                            }))[0]
                                        : null
                                    }
                                    options={categories.map((category) => ({
                                      label: category.name,
                                      value: category.id,
                                    }))}
                                    getOptionLabel={(option: OptionLabel) =>
                                      option.label
                                    }
                                    onChange={(_, value: OptionLabel) => {
                                      if (!value) {
                                        handleChange({
                                          target: {
                                            name: 'categoryId',
                                            value: '',
                                          },
                                        } as any);
                                        return;
                                      }
                                      handleChange({
                                        target: {
                                          name: 'categoryId',
                                          value: `${value.value}`,
                                        },
                                      } as any);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Categoría"
                                        placeholder="Categoría"
                                        error={Boolean(
                                          touched.categoryId &&
                                            errors.categoryId
                                        )}
                                        helperText={
                                          touched.categoryId &&
                                          errors.categoryId
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} />
                              </Grid>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>

                        {/* Información Personal */}
                        <TimelineItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <TimelineOppositeContent
                            sx={{
                              width: '100px',
                              flex: 'none',
                            }}
                            color="text.secondary"
                          >
                            Solicitante
                          </TimelineOppositeContent>
                          <TimelineSeparator
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TimelineDot
                              sx={{
                                marginTop: 0,
                                top: theme.spacing(1.2),
                              }}
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            sx={{
                              pb: 0,
                            }}
                          >
                            <Box>
                              <Typography variant="h5" gutterBottom>
                                Completa tus datos
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(
                                      touched.firstName && errors.firstName
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.firstName && errors.firstName
                                    }
                                    label="Nombre"
                                    name="firstName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName || ''}
                                    variant="outlined"
                                    placeholder="Escibe tu nombre aquí..."
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    error={Boolean(
                                      touched.lastName && errors.lastName
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.lastName && errors.lastName
                                    }
                                    label="Apellido"
                                    name="lastName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName || ''}
                                    variant="outlined"
                                    placeholder="Escibe tu apellido aquí..."
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <TextField
                                    error={Boolean(
                                      touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Correo"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email || ''}
                                    variant="outlined"
                                    placeholder="Escibe tu correo aquí..."
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} />
                              </Grid>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>

                        {/* Detalles */}
                        <TimelineItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <TimelineOppositeContent
                            sx={{
                              width: '100px',
                              flex: 'none',
                            }}
                            color="text.secondary"
                          >
                            Detalles
                          </TimelineOppositeContent>
                          <TimelineSeparator
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TimelineDot
                              sx={{
                                marginTop: 0,
                                top: theme.spacing(1.2),
                              }}
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            sx={{
                              pb: 0,
                            }}
                          >
                            <Box>
                              <Typography variant="h5" gutterBottom>
                                Coméntanos tu solicitud
                              </Typography>
                              <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                  <TextField
                                    error={Boolean(
                                      touched.subject && errors.subject
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.subject && errors.subject
                                    }
                                    label="Asunto"
                                    name="subject"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.subject || ''}
                                    variant="outlined"
                                    placeholder="Ej. No puedo acceder a mi cuenta"
                                  />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <EditorWrapper>
                                    <ReactQuill
                                      onChange={(value) =>
                                        handleChange({
                                          target: {
                                            name: 'body',
                                            value:
                                              value === '<p><br></p>'
                                                ? ''
                                                : value,
                                          },
                                        } as any)
                                      }
                                      value={values.body || ''}
                                      placeholder="Escribe los detalles de tu solicitud aquí..."
                                    />
                                    {touched.body && errors.body && (
                                      <Text color="error">{errors.body}</Text>
                                    )}
                                  </EditorWrapper>
                                </Grid>
                                <Grid item xs={12} md={6} />
                              </Grid>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>

                        {/* Adjuntos */}
                        <TimelineItem
                          sx={{
                            p: 0,
                          }}
                        >
                          <TimelineOppositeContent
                            sx={{
                              width: '100px',
                              flex: 'none',
                            }}
                            color="text.secondary"
                          >
                            Adjuntos
                          </TimelineOppositeContent>
                          <TimelineSeparator
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TimelineDot
                              sx={{
                                marginTop: 0,
                                top: theme.spacing(1.2),
                              }}
                              variant="outlined"
                              color="primary"
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            sx={{
                              pb: 4,
                            }}
                          >
                            <Typography variant="h5" gutterBottom>
                              Adjunta las pruebas necesarias
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              ¿Deseas adjuntar algún archivo? Puedes hacerlo
                              aquí.
                            </Typography>
                            <Box
                              display="flex"
                              mt={2}
                              alignItems="center"
                              justifyContent="space-around"
                            >
                              <BoxUploadWrapper {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragAccept && (
                                  <>
                                    <AvatarSuccess variant="rounded">
                                      <CheckTwoToneIcon />
                                    </AvatarSuccess>
                                    <Typography
                                      sx={{
                                        mt: 2,
                                      }}
                                    >
                                      Suelta los archivos aquí para subirlos
                                    </Typography>
                                  </>
                                )}
                                {isDragReject && (
                                  <>
                                    <AvatarDanger variant="rounded">
                                      <CloseTwoToneIcon />
                                    </AvatarDanger>
                                    <Typography
                                      sx={{
                                        mt: 2,
                                      }}
                                    >
                                      No puedes subir estos archivos
                                    </Typography>
                                  </>
                                )}
                                {!isDragActive && (
                                  <>
                                    <AvatarWrapper variant="rounded">
                                      <CloudUploadTwoToneIcon />
                                    </AvatarWrapper>
                                    <Typography
                                      sx={{
                                        mt: 2,
                                        textAlign: 'center',
                                      }}
                                    >
                                      Arrastra y suelta los archivos aquí o{' '}
                                      <br /> haz click para seleccionarlos
                                    </Typography>
                                  </>
                                )}
                              </BoxUploadWrapper>
                              <Box>
                                {attachmentsUrls.length > 0 && (
                                  <>
                                    <Alert
                                      sx={{
                                        py: 0,
                                        mt: 2,
                                      }}
                                      severity="success"
                                    >
                                      Has subido <b>{attachmentsUrls.length}</b>{' '}
                                      {attachmentsUrls.length === 1
                                        ? 'archivo'
                                        : 'archivos'}
                                    </Alert>
                                    <Divider
                                      sx={{
                                        mt: 2,
                                      }}
                                    />
                                    <List disablePadding component="div">
                                      {files}
                                    </List>
                                  </>
                                )}
                                {uploadingAttachment && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      mt: 2,
                                    }}
                                  >
                                    <CircularProgress size="1rem" />
                                    <Typography
                                      sx={{
                                        mt: 2,
                                        textAlign: 'center',
                                      }}
                                    >
                                      Subiendo archivos...
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>

                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '1rem',
                          }}
                        >
                          <Button
                            color="secondary"
                            onClick={() => handleReset(resetForm)}
                          >
                            Cancelar
                          </Button>
                          <Button
                            type="submit"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                            disabled={
                              Boolean(errors.submit) ||
                              isSubmitting ||
                              uploadingAttachment
                            }
                            variant="contained"
                          >
                            Crear
                          </Button>
                        </Box>
                      </Timeline>
                    </form>
                  )}
                </Formik>
              ) : (
                <Box px={4} py={8}>
                  <Container maxWidth="sm">
                    <AvatarSuccess>
                      <CheckTwoToneIcon />
                    </AvatarSuccess>
                    <Collapse in={openAlert}>
                      <Alert
                        sx={{
                          mt: 5,
                        }}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        severity="info"
                      >
                        Un mensaje de confirmación ha sido enviado a tu correo
                      </Alert>
                    </Collapse>

                    <Typography
                      align="center"
                      sx={{
                        pt: 5,
                        px: 10,
                      }}
                      variant="h1"
                    >
                      Código: {code}
                    </Typography>
                    <Typography
                      align="center"
                      sx={{
                        pt: 2,
                        pb: 4,
                        lineHeight: 1.5,
                        px: 10,
                      }}
                      variant="h3"
                    >
                      El avance de tu requerimiento será notificado a tu correo.
                      Además, puedes revisar el estado de tu ticket buscándolo
                      con su código.
                    </Typography>

                    <Button fullWidth variant="contained" href="/">
                      Búsca tu ticket
                    </Button>
                  </Container>
                </Box>
              )}
            </Box>
          </Card>
          <Box
            my={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              component="span"
              variant="subtitle2"
              color="text.primary"
              fontWeight="bold"
            >
              ¿Ya tienes un ticket?
            </Typography>{' '}
            <Link component={RouterLink} to="/">
              <b>Búscalo aquí</b>
            </Link>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default CreateTicketComponent;
