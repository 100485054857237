import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import Authenticated from 'src/components/Authenticated';
import SearchTicket from 'src/content/pages/Tickets/Search';
import CreateTicket from 'src/content/pages/Tickets/Create';

import homeRoutes from './home';

const router: RouteObject[] = [
  {
    path: '',
    element: (
      <Authenticated>
        <SearchTicket />
        <Outlet />
      </Authenticated>
    ),

    // children: [
    //   // ...base
    //   {
    //     path: 'home',
    //     children: homeRoutes,
    //   },
    // ],
  },
  {
    path: '/create-ticket',
    element: <CreateTicket />,
  },
  {
    // Home routes
    path: 'home',
    element: (
      <Authenticated>
        <Outlet />
      </Authenticated>
    ),
    children: homeRoutes,
  },
];

export default router;
