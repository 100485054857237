import { useState, useEffect } from 'react';
import queryString from 'query-string';

import { useApi } from './useApi';
import { Group, LevelName } from 'src/utils/types';
import { generalConfig } from 'src/config';

type UseItemsProps = {
  level?: number;
  parentId?: number;
};

export const useGroups = (props: UseItemsProps) => {
  const { get } = useApi();
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [levelNames, setLevelNames] = useState<LevelName[]>([]);

  const getGroups = async () => {
    try {
      setLoading(true);
      const params = {
        tenantId: generalConfig.tenant.id,
        level: props.level,
        parentId: props.parentId,
        ignorePagination: true,
      };
      const query = queryString.stringify(params);
      const data = await get<Group[]>('/groups?' + query);
      setGroups(data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getLevelNames = async () => {
    try {
      setLoading(true);
      const params = {
        tenantId: generalConfig.tenant.id,
        ignorePagination: true,
      };
      const query = queryString.stringify(params);
      const data = await get<LevelName[]>('/levels-names?' + query);
      setLevelNames(data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLevelNames();
    getGroups();
  }, []);

  return {
    groups,
    levelNames,
    loading,
    error,
  };
};
