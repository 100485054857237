import ReactDOM from 'react-dom';
import 'src/mocks';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import 'nprogress/nprogress.css';
import { Zoom } from '@mui/material';

import ScrollTop from 'src/hooks/useScrollTop';
import { SnackbarCloseButton } from './components/SnackbarClose';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/JWTAuthContext';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <SnackbarProvider
            maxSnack={50}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            hideIconVariant
            autoHideDuration={4000}
            TransitionComponent={Zoom}
            action={(key) => <SnackbarCloseButton snackbarKey={key} />}
          >
            <AuthProvider>
              <App />
            </AuthProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
